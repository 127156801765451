<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-100"
        />

        <download-excel
          :data="exportItems"
          :fields="exportFields"
          :filename="'Employee'"
          :sheetname="'Employee'"
          class="cursor-pointer ml-1"
        >
          <b-button variant="" size="sm" class="d-flex mr-1 export-btn">
            <feather-icon icon="FileIcon" size="14" class="text-success" />
            <label style="font-size: 14px; margin-left: 5px; cursor: pointer">
              Export
            </label>
          </b-button>
        </download-excel>

        <b-button
          variant=""
          size="sm"
          class="d-flex mr-1 export-btn"
          v-if="getRole() != 'director'"
        >
          <feather-icon icon="FileIcon" size="14" class="text-info" />
          <label
            style="
              font-size: 14px;
              margin-left: 5px;
              cursor: pointer;
              width: 80px;
            "
          >
            Make PMP
          </label>
        </b-button>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input
          id="searchInput"
          v-model="filter"
          size="sm"
          type="search"
          placeholder="Search"
        />
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(profile_photo_url)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-link
                :to="{
                  name: 'performance/goal/review',
                  params: {
                    id: data.value.id,
                    type: 'final',
                    isMeeting: data.value.status == 'approved_meeting' ? true : false,
                  },
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="text-primary"
                ></feather-icon>
              </b-link>
            </div>
          </template>

          <template #cell(status)="data">
            <div class="text-center">
              <b-badge
                :variant="
                  data.value == 'new'
                    ? 'primary'
                    : data.value == 'send_back'
                    ? 'info'
                    : data.value == 'approved_meeting'
                    ? 'warning'
                    : 'success'
                "
                pill
                class="text-capitalize"
              >
                {{
                  data.value == "new"
                    ? "New"
                    : data.value == "send_back"
                    ? "Send Back"
                    : data.value == "approved_meeting"
                    ? "Meeting"
                    : "Approved"
                }}
              </b-badge>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-3">No data available.</div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            Showing
            {{ perPage > totalRows ? totalRows : perPage }} of
            {{ totalRows }} entries
          </span>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<style>
table > thead > tr > th {
  white-space: nowrap;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "user_id",
          label: "Employee",
        },
        { key: "due_date", label: "Due Date", sortable: "true" },
        {
          key: "status",
          label: "Status",
          sortable: "true",
        },
        { key: "actions", label: "Actions" },
      ],
      items: [],

      // export excel
      exportFields: {
        Employee: "employee",
        "Strategic Goal": "title",
        Description: "description",
        "Dua Date": "due_date",
        Weight: "weight",
        Target: "target",
        "Midyear Realization": "midyear",
        "Final Realization": "final",
        "Overall Employee Score": "overall_employee_score",
        "Overall Manager Score": "overall_manager_score",
        "Overall Employee Behavior": "overall_employee_behavior",
        "Overall Manager Behavior": "overall_manager_behavior",
        Status: "status",
      },
      exportItems: [],
      isMeeting: false,
      score: [
        "0 - Has not met Objectives",
        "1 - Met some Objectives but not all",
        "2 - Met Objectives",
        "3 - Exceeded some Objectives & met others",
        "4 - Exceeded all Objectives",
      ],
      behavior: ["", "1 - Improve", "2 - Appropriate", "3 - Excellent"],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
  },
  methods: {
    fetchData() {
      let params = {};

      if (this.getRole() == "manager") {
        params = {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),        
        };
      } else if (this.getRole() == "director") {
        params = {};
      } else {
        params = {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
        };
      }

      this.callApi({
        method: "GET",
        url: "/final_evaluations/fetch",
        params: params,
        success: (response) => {
          response.result.forEach((item) => {
            this.items.push({
              user_id: item.user.name,
              strategic_goals: item.strategic_goals,
              key_performance_indicator: item.key_performance_indicator,
              weight: item.weight,
              target: item.target,
              due_date: item.goal.due_date,
              midyear_evaluation:
                item.midyear_evaluation != null
                  ? item.midyear_evaluation.midyear_realization
                  : "-",
              final_evaluation:
                item.final_evaluation != null
                  ? item.final_evaluation.final_realization
                  : "-",
              status: item.status,
              actions: {id: item.user.id, status: item.status},
            });
          });
          //
          this.totalRows = this.items.length;
        },
        error: (error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Notification",
          //     icon: "BellIcon",
          //     text: "Failed to fetch data",
          //     variant: "error",
          //   },
          // });
        },
      });

      this.callApi({
        url: "final_evaluations/fetch",
        method: "GET",
        params: {
          export: 1,
        },
        success: (res) => {
          res.result.map((item) => {
            this.exportItems.push({
              title: item.goal.strategic_goals,
              employee: item.user.name,
              description: item.goal.key_performance_indicator,
              weight: item.goal.weight,
              target: item.goal.target,
              due_date: item.goal.due_date,
              midyear: item.midyear_evaluation.midyear_realization,
              final: item.final_realization,
              overall_employee_score: this.score[item.final_employee_score],
              overall_manager_score: this.score[item.final_manager_score],
              overall_employee_behavior:
                this.behavior[item.final_employee_behavior],
              overall_manager_behavior:
                this.behavior[item.final_manager_behavior],
              status:
                item.final_goal_status == 1 ? "Completed" : "Not Completed",
            });
          });
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteEmployee(id) {
      this.callApi({
        method: "DELETE",
        url: "/users/delete/" + this.hashid(id),
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Delete successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to delete",
              variant: "error",
            },
          });
        },
      });
    },
  },
};
</script>
